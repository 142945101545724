<template lang="pug">
	main#recovery
		.wrapper
			Logo
			ChangePassword(v-if="hasToken")
			ValidateEmail(v-else)
</template>

<script>
import Logo from '@components/Logo/Logo.vue'
import ValidateEmail from '@sections/Recovery/ValidateEmail/ValidateEmail'
import ChangePassword from '@sections/Recovery/ChangePassword/ChangePassword'

export default {
	name: "view-recovery",
	components: {
		ChangePassword,
		ValidateEmail,
		Logo
	},
	metaInfo() {
		return {
			title: 'recovery',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
	data() {
		return { 
			hasToken: false
		}
	},
	mounted() {
		setTimeout(() => {
			if(this.$route.query.token)
				this.hasToken = true
		})
	}
}
</script>

<style lang="stylus" scoped src="./Recovery.styl"></style>
