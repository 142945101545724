<template lang="pug">
	section#changePassword
		.wrapper
			h3 Redefinir senha
			p Digite e confirme sua nova senha
			.input(:class="{'error': errors.password}")
				input(v-model="form.password", placeholder="Senha", type="password")
			.input(:class="{'error': errors.password}")
				input(v-model="confirmPassword", placeholder="Confirmar Senha", type="password")
				span(v-if="errors.password") {{errors.password[0]}}
			button(@click="send()", :disabled="disabled", v-html="textButton").btnX 
</template>

<script>
export default {
	name: "section-change-password",
	data(){
		return {
			form: {
				password: '',
				code: '',
				isAdmin: true
			},
			confirmPassword: '',
			disabled: false,
			textButton: "Confirmar",
			errors: {}
		}
	},
	methods: {
		send(){
			this.disabled = true
			this.errors = {}
			this.textButton = "Enviando..."
			if(this.form.password === this.confirmPassword){
				this.form.code = this.$route.query.token
				this.$axios.put(`/auth/forgot-password`, this.form)
					.then(response => {
						this.textButton = "Enviado"
						setTimeout(() => {
							this.$router.push({name: 'Home'})
						}, 4000)
					}).catch(error => {
						this.errors = error.response.data
					}).finally(() => {
						setTimeout(()=> {
							this.disabled = false
							this.textButton = "Confirmar"
						}, 4000)

					})
			}
			else {
				this.errors.password = "Senhas diferentes"
			}
		},
	}
}
</script>

<style lang="stylus" scoped src="./ChangePassword.styl"></style>
