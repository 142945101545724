<template lang="pug">
	section#validateEmail
		.wrapper
			h3 Esqueceu sua senha?
			.content(v-if="!enviado")
				p Você receberá por e-mail um link para cadastrar uma nova senha.
				.input
					input(v-model="form.email", placeholder="E-mail", type="text")
				button(@click="sendEmail()" :disabled="disabled" v-html="btnText").btnX 
			.content(v-else)
				p E-mail enviado, verifique sua caixa de entrada!
</template>

<script>
export default {
	name: "section-validate-email",
	data() {
		return {
			form: {
				email: null,
				isAdmin: true
			},
			disabled: false,
			btnText: "Enviar",
			enviado: false
		}
	},
	methods: {
		sendEmail() {
			this.btnText = "Enviando..."
			this.disabled = true

			this.$axios.post('/auth/forgot-password', this.form)
				.then(response => {
					this.enviado = true
				}).catch(error => {
					this.btnText = "Ocorreu um erro"
				}).finally(() => {
					setTimeout(()=> {
						this.btnText = "Enviar"
						this.disabled = false
					}, 3000)
				})
		}
	}
}
</script>

<style lang="stylus" scoped src="./ValidateEmail.styl"></style>
